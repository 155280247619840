header{
    height: 85vh;
    padding-top: 4rem;
    overflow: hidden;
    margin-bottom: 3rem;
}

.header__container{
    text-align: center;
    position: relative;
}


/*===================== CTA ===================*/

.cta{
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
}

/*================== HEADER SOCIALS ======================*/

.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    left: 0;
    bottom: 2rem;
    position: absolute;
}

.header__socials::after{
    content: "";
    width: 1px;
    height: 3rem;
    background: var(--color-primary);
}

/*================== ME ======================*/

.header__container .me{
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 33rem;
    position: relative;
    left: calc(50% - 11rem);
    margin-top: 3rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 2rem 1.5rem 1.5rem 1.5rem;
}

/*================== SCROLL DOWN ======================*/

.scroll__down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* =============== MEDIA QUERIES MD =============== */

@media screen and (max-width: 1024px) {
    header{
        height: 55rem;
    }
}

/* =============== MEDIA QUERIES SM =============== */

@media screen and (max-width: 600px) {
    header{
        height: 40rem;
        margin-bottom: 2rem;
    }
    .header__socials{
        display: none;
    }
    .scroll__down{
        display: none;
    }
    .container.header__container img{
        width: 70%;
        margin-left: 14%;
    }
    .header__container .me{
        background: linear-gradient(rgba(0, 0, 0, 0.4), transparent);
        width: 18rem;
        height: 20rem;
        position: relative;
        margin-left: 9%;
        margin-top: 3rem;
        border-radius: 12rem 12rem 0 0;
        overflow: hidden;
        padding: 2rem 1.5rem 1.5rem 1.5rem;
    }
}
