 #experience{
     margin-bottom: 4rem;
 }
 .experience__container{
     display: grid;
     grid-template-columns: 1fr 1fr;
     gap: 2rem;
 }

 .experience__container > div{
     background: var(--color-bg-variant);
     padding: 2.4rem 5rem;
     border-radius: 2rem;
     border: 1px solid transparent;
     transition: var(--transition);
 }

 .experience__container > div:hover{
     background: transparent;
     border-color: var(--color-primary-variant);
     cursor: default;
 }

 .experience__container > div h3{
     text-align: center;
     margin-bottom: 2rem;
     color: var(--color-primary);
 }

 .experience__content{
     display: grid;
     grid-template-columns: 1fr 1fr;
     row-gap: 2rem;
 }

 .experience__details{
    display: flex;
    gap: 1rem;
 }

 .experience__details-icon{
     margin-top: 6px;
     color: var(--color-primary);
     font-size: 1.5rem;
 }


 /* =============== MEDIA QUERIES MD =============== */

@media screen and (max-width: 1024px) {
    .experience__container{
        grid-template-columns: 1fr;
    }

    .experience__container > div{
        width: 80%;
        padding: 2rem;
        margin: 0 auto;

    }

    .experience__content{
        padding: 1rem;
    }
}

/* =============== MEDIA QUERIES SM =============== */

@media screen and (max-width: 600px) {
    #experience{
        margin-bottom: 3rem;
    }
    
    .experience__container > div h3 {
        margin-bottom: 0.5rem;
    }

    .experience__container > dev{
        width: 100%;
        padding: 2rem 1rem;
    }

    .experience__details{
        margin: 2px;
     }

     .experience__content{
        
        margin-left: 0px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 1rem;
    }

    .experience__details {
        justify-content: center;
        display: flex;
        grid-gap: 8rem;
        gap: 0.5rem;
        display: inline;
    }

    .experience__container{
        justify-content: center;
    }

}
