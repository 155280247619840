.cc > h5{
    text-align: center;
    font-size: 15px;
    color: var(--color-light);
}

.container.cc__container{
    width: 80%;
    height: fit-content;
}
.cc > img{
    width: 40%;
    border-radius: 2rem;
    margin: auto;
    border: 0.4rem solid var(--color-primary-variant);
}
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    visibility: hidden;
}

/* =============== MEDIA QUERIES MD =============== */

@media screen and (max-width: 1024px) {
    .container.cc__container{
        width: 100%;
    }
    .cc > img{
        width: 60%;
    }
}

/* =============== MEDIA QUERIES SM =============== */

@media screen and (max-width: 600px) {
    .container.cc__container{
        width: 100%;
    }
    .cc > img{
        width: 80%;
    }
}